import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import "./LoginForm.styles.sass";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { generate2FAThunkAction, postSignInThunkAction } from "../../../redux/auth/auth.slice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { capitalize } from "lodash";
import { translate } from "@careexpand/cex-core-components/esm";
import Spiner from "../../../components/common/Spiner/Spiner";
import { useTranslation } from "react-i18next";
import { CEXModal } from "@careexpand/cex-core-components/esm";
import Swal from "sweetalert2";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faEyeSlash, faEye } from '@fortawesome/pro-light-svg-icons';

const Login = ({ }: any) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>();
    const dispatch = useDispatch<AppDispatch>();
    const [loginError, setLoginError] = useState(false);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    // const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [t]: any = useTranslation("global");
    const [welcomeParam, setWelcomeParam] = useState("");
    const [userNotVerified, setUserNotVerified] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(
        false
    );
    const [accountVerified, setAccountVerified] = useState(false);

    const sendPostRequest = (url: string, params: any) => {
        return new Promise((resolve, reject) => {
            axios
                .post(url, params, { headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` } })
                .then((res: any) => {
                    resolve(res.data);
                })
                .catch((err: any) => {
                    reject(err);
                });
        });
    };
    const sendGetRequest = (url: string, params: any) => {
        return new Promise((resolve, reject) => {
            const requestConfig = {
                headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
                params: { ...params },
            };

            axios
                .get(url, requestConfig)
                .then((res: any) => {
                    resolve(res);
                })
                .catch((err: any) => {
                    reject(err);
                });
        });
    };
    const clearStorage = () => {
        localStorage.removeItem("Token");
        localStorage.removeItem("RefreshToken");
        localStorage.removeItem("missingAgreements");
        localStorage.removeItem("disabledLeftNavItems");
    };
    const openSaaSAgreement = () => {
        return new Promise((resolve, reject) => {
            const url =
                (process.env.REACT_APP_LEGACY_API_URL || "http://localhost:3000") + `/CareExpand_SAAS_Agreement.html`;

            sendGetRequest(url, {}).then((resultHTML: any) => {
                return Swal.fire({
                    title: `<div style="overflow-y: auto ;font-family:Raleway;font-weight:700;font-size:24px">SaaS Agreement<div>`,
                    html: `<div style="height:400px; overflow-y: auto;font-family:Inter;font-weight:400;font-size:12px">${resultHTML.data}<div>`,
                    confirmButtonText: capitalize(translate("confirm")),
                    showCancelButton: true,
                    cancelButtonText: capitalize(translate("cancel")),
                    allowOutsideClick: false,
                })
                    .then(async (result: any) => {
                        if (result.isConfirmed) {
                            const url =
                                (process.env.REACT_APP_LEGACY_API_URL || "http://localhost:3000") + `/acceptAgreement`;
                            const params = { agreement: "CareexpandSaaS" };
                            await sendPostRequest(url, params);
                            resolve("confirmed");
                        } else {
                            clearStorage();
                            window.location.href = `${process.env.REACT_APP_HOME_APP_URL || "http://localhost:3000"}`;

                            reject("cancelled");
                        }
                    })
                    .catch(() => {
                        clearStorage();
                        window.location.href = `${process.env.REACT_APP_AUTH_APP_URL || "http://localhost:3000"}`;

                        reject("cancelled");
                    });
            });
        });
    };

    const openBAAAgreement = () => {
        return new Promise((resolve, reject) => {
            const url =
                (process.env.REACT_APP_LEGACY_API_URL || "http://localhost:3000") +
                `/modules/agreements/Careexpand_BAA_12_2018.html`;

            //axios.get(url, requestConfig);
            sendGetRequest(url, {}).then((resultHTML: any) => {
                return Swal.fire({
                    title: `<div style="overflow-y: auto ;font-family:Raleway;font-weight:700;font-size:24px">BAA Agreement<div>`,
                    html: `<div style="height:400px; overflow-y: auto;font-family:Inter;font-weight:400;font-size:12px">${resultHTML.data}<div>`,
                    confirmButtonText: capitalize(translate("confirm")),
                    showCancelButton: true,
                    cancelButtonText: capitalize(translate("cancel")),
                    allowOutsideClick: false,
                })
                    .then(async (result: any) => {
                        if (result.isConfirmed) {
                            const url =
                                (process.env.REACT_APP_LEGACY_API_URL || "http://localhost:3000") + `/acceptAgreement`;

                            const params = { agreement: "CareexpandBAA" };

                            //await sendMultipartPostRequest(url, formData);
                            await sendPostRequest(url, params);
                            resolve("confirmed");
                        } else {
                            clearStorage();
                            window.location.href = `${process.env.REACT_APP_AUTH_APP_URL || "http://localhost:3000"}`;

                            reject("cancelled");
                        }
                    })
                    .catch(() => {
                        clearStorage();
                        window.location.href = `${process.env.REACT_APP_AUTH_APP_URL || "http://localhost:3000"}`;

                        reject("cancelled");
                    });
            });
        });
    };

    const openDoctorCertificationAgreement = () => {
        return new Promise((resolve, reject) => {
            const url =
                (process.env.REACT_APP_LEGACY_API_URL || "http://localhost:3000") +
                `/modules/agreements/Doctor_Certification_01_2019.html`;

            //axios.get(url, requestConfig);
            sendGetRequest(url, {}).then((resultHTML: any) => {
                return Swal.fire({
                    title: `<div style="overflow-y: auto ;font-family:Raleway;font-weight:700;font-size:24px">Doctor Certificate Agreement<div>`,
                    html: `<div style="height:400px; overflow-y: auto;font-family:Inter;font-weight:400;font-size:12px">${resultHTML.data}<div>`,
                    confirmButtonText: capitalize(translate("confirm")),
                    showCancelButton: true,
                    cancelButtonText: capitalize(translate("cancel")),
                    allowOutsideClick: false,
                })
                    .then(async (result: any) => {
                        if (result.isConfirmed) {
                            const url =
                                (process.env.REACT_APP_LEGACY_API_URL || "http://localhost:3000") + `/acceptAgreement`;
                            const params = { agreement: "DoctorCertification" };
                            await sendPostRequest(url, params);

                            resolve("confirmed");
                        } else {
                            clearStorage();
                            window.location.href = `${process.env.REACT_APP_AUTH_APP_URL || "http://localhost:3000"}`;

                            reject("cancelled");
                        }
                    })
                    .catch(() => {
                        clearStorage();
                        window.location.href = `${process.env.REACT_APP_AUTH_APP_URL || "http://localhost:3000"}`;
                        reject("cancelled");
                    });
            });
        });
    };

    const showAgreementModals = async (missingAgreement: string[]) => {
        if (missingAgreement.indexOf("saas") >= 0) await openSaaSAgreement();
        if (missingAgreement.indexOf("baa") >= 0) await openBAAAgreement();
        if (missingAgreement.indexOf("doctorCert") >= 0) await openDoctorCertificationAgreement();
    };

    const closeModal = () => {
        setLoginError(false);
        setUserNotVerified(false);
        setOpenModal(false);
        navigate("/login");

    };

    const handleOnSubmit = async (values: any) => {
        setIsSubmitting(true);
        await dispatch(
            postSignInThunkAction({
                data: {
                    key: values.emailInput,
                    password: values.passwordInput,
                    timezone: "2022-12-27T18:02:14.894Z",
                },
            })
        ).then(async (res: any) => {
            if(res.error){
                if (res.error.message.includes("403")) {
                    // user not verified
                    setUserNotVerified(true);
                }else {
                    setLoginError(true);
                }
            } else {
                const agreements = localStorage.getItem("missingAgreements")?.split(",");
                if (!agreements || agreements.length <= 0) {
                    window.location.assign("/home#/home");
                } else {
                    if (agreements.length > 0) {
                        await showAgreementModals(agreements);
                        localStorage.removeItem("missingAgreements");
                        dispatch(generate2FAThunkAction({ data: {} })).then((res: any) => {
                            if (res.payload) {
                                window.location.assign("/#/2fa");
                            }
                        }

                        );
                    }

                }
            }
        }).finally(() => {
            setIsSubmitting(false);
            });
    };

    useEffect(() => {
        const queryString = location.search; 
        const urlParams = new URLSearchParams(queryString);

        const param = urlParams.get("redirectFrom");
        const action = urlParams.get("action")
        setWelcomeParam(param ? param : "");
        
        if (action === 'email') {
           setOpenModal(true);
        } else if (param === 'referral') {
           setAccountVerified(true);
        } else {
            setOpenModal(false);
            setAccountVerified(false);
        }

    }, [location, openModal]);




    const emailSentSubtitle = "An email has been sent to your inbox, please verify it to login";

    return (
        <div className="userDetailsForm">
            {
                openModal 
                && (
                    <CEXModal type="info" handleModal={() => closeModal()} title="Welcome" subtitle={emailSentSubtitle} />
                )}
            {welcomeParam === "register"
                && (
                    <CEXModal handleModal={() => closeModal()} title={"Email sent"} subtitle={'An email has been sent'} type={"info"} />
                )}
            {loginError && (
                <CEXModal
                    type="info"
                    handleModal={() => closeModal()}
                    title="Login error"
                    subtitle="Incorrect password or email"
                />
            )}
            {userNotVerified && (
                <CEXModal
                    type="info"
                    handleModal={() => closeModal()}
                    title="Email not verified"
                    subtitle="Please verify your email to login"
                />
            )}
            {accountVerified && (
                <CEXModal
                    type="info"
                    handleModal={() => {
                        setAccountVerified(false);
                        navigate("/login");
                    }}
                    title="Account Verified"
                    subtitle="Your account has been successfully verified"
                />
            )}
            {isSubmitting && <Spiner />}
            <Formik
                initialValues={{ emailInput: "", passwordInput: "" }}
                validateOnChange
                validateOnBlur
                onSubmit={(values, actions) => {
                    setIsSubmitting(true);
                    if (values.emailInput === "" || values.passwordInput === "") {
                        actions.setStatus("hola");
                    } else {
                        handleOnSubmit(values);
                    }
                }}
                validationSchema={yup.object().shape({
                    emailInput: yup
                        .string()
                        .required("Please enter a valid email address")
                        .email("Please enter a valid email address"),
                    passwordInput: yup
                        .string()
                        .required("Please enter a password")
                        .min(8, "Password must be at least 8 characters"),
                })}
            >
                {({ errors, touched, submitCount }) => (
                    <Form className="userDetailsForm" autoComplete="off">
                        <div className="inputFieldDiv">
                            <div className="rowInputFields">
                                <div className="pi-input-skel-block">
                                    <div className="pi-input">
                                        <label className="pi-label" htmlFor="">{t("register.email")}</label>
                                        <div className="pi-input-skel">
                                            <span className="pi-input-prefix" style={{ padding: '8px 8px' }}>
                                                <FontAwesomeIcon icon={faEnvelope} size="2xl" style={{ width: '17px' }} />
                                            </span>
                                            <Field
                                                name="emailInput"
                                                type="email"
                                                placeholder={t("register.email-label")}
                                                className="pi-input-ele"
                                            />
                                        </div>
                                    </div>
                                    <label className="input__error">
                                        {errors.emailInput && (touched.emailInput || submitCount > 0) && errors.emailInput}
                                    </label>
                                </div>
                                <br />
                                <div className="pi-input-skel-block">
                                    <div className="pi-input">
                                        <label className="pi-label" htmlFor="">{t("register.password")}</label>
                                        <div className="pi-input-skel">
                                            <span className="pi-input-prefix" style={{ padding: '8px 8px', cursor: 'pointer' }} onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
                                                <FontAwesomeIcon icon={isPasswordVisible ? faEye : faEyeSlash} size="2xl" style={{ width: '17px' }} />
                                            </span>
                                            <Field
                                                name="passwordInput"
                                                type={isPasswordVisible ? "text" : "password"}
                                                placeholder="Password"
                                                className="pi-input-ele"
                                            />
                                        </div>
                                    </div>
                                    <label className="input__error">
                                        {errors.passwordInput &&
                                            (touched.passwordInput || submitCount > 0) &&
                                            errors.passwordInput}
                                    </label>
                                </div>
                                <br />
                                <div className="login-passwordResetLinkdiv">
                                    <div className="commonText">
                                        <Link to="/forgot" className="commonText" style={{ textDecoration: "none" }}>
                                            Forgot your password?
                                        </Link>
                                    </div>
                                </div>
                                <div className="link-button-container">
                                    <a className="goToRegisterLink" href={`${process.env.REACT_APP_LEGACY_API_URL}/patients.html`}>
                                        {t("login.patient-portal")}
                                    </a>
                                    <div>
                                        <button
                                            type="submit"
                                            disabled={isSubmitting}
                                            className="login_blueButton"
                                            style={{
                                                fontSize:
                                                    window.innerWidth <= 320
                                                        ? "11px"
                                                        : window.innerWidth === 768
                                                            ? "10px"
                                                            : window.innerWidth >= 426 && window.innerWidth <= 514
                                                                ? "10px"
                                                                : "14px",
                                            }}
                                        >
                                            Login
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default Login;
